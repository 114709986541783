import PropTypes from "prop-types";

// Material UI
import { Box, Avatar } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";

// Utilidades
import { getBackgroundPosition } from "@utils/Utilities";
import { isNullOrUndefined } from "@utils/validations";

// Assets
import profilesImage from "@assets/img/profiles.webp";
const ProfilePhoto = (props) => {
  const { idFotoPerfil = null, size = 100, sx = {}, sxIcon = {}, sxAvatar = {}, icon = false } = props;

  return !isNullOrUndefined(idFotoPerfil) ? (
    <Box
      sx={{
        width: size,
        height: size,
        borderRadius: "100%",
        backgroundImage: `url(${profilesImage})`,
        backgroundSize: "1000%",
        backgroundPosition: getBackgroundPosition({ image: idFotoPerfil, size }),
        backgroundRepeat: "no-repeat",
        backgroundColor: "white",
        display: "inline-block",
        cursor: "pointer",
        ...sx,
      }}
    />
  ) : icon ? (
    <AccountCircle sx={sxIcon} />
  ) : (
    <Avatar sx={{ width: size, height: size, ...sxAvatar }} src="/broken-image.jpg" />
  );
};

ProfilePhoto.propTypes = {
  idFotoPerfil: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.number,
  sx: PropTypes.object,
  sxIcon: PropTypes.object,
  sxAvatar: PropTypes.object,
  icon: PropTypes.bool,
};

export default ProfilePhoto;
