import { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { icon } from "leaflet";
import { MapContainer, TileLayer, Marker, ZoomControl, Tooltip, GeoJSON } from "react-leaflet";

// Componentes
import SearchField from "@components/Maps/InternalComponents/GeoSearch/SearchControl";

// Data
import polygons_mun from "@data/polygons/municipios.json";

const DragMap = (props) => {
  const {
    handleCoords,
    ubicacion = [],
    center = [20.816157, -101.021312],
    zoom = 9,
    styleMap = {},
    tooltip = "Esta es la ubicación encontrada",
  } = props;

  // 20.816157, -101.021312, 21.0185800, -101.2591000
  const [latLocation, longLocation] = ubicacion;
  const [latDefault, longDefault] = center;

  const ICON = icon({
    iconUrl:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-violet.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const style = () => ({
    fillColor: "#0066FF",
    weight: 2,
    opacity: 1,
    color: "white",
    dashArray: "3",
    fillOpacity: 0.1,
  });

  const [latlng, setLatLng] = useState({
    latitud: latLocation ?? latDefault,
    longitud: longLocation ?? longDefault,
  });
  const handleCoordsLocal = (e) => handleCoords(e);

  const eventHandlers = useMemo(
    () => ({
      dragend(e) {
        let coord = e.target._latlng;
        setLatLng({ latitud: coord.lat, longitud: coord.lng });
        handleCoords({ latitud: coord.lat, longitud: coord.lng });
      },
    }),
    // eslint-disable-next-line
    []
  );

  return (
    <MapContainer
      center={center}
      zoom={zoom}
      zoomControl={false}
      style={{ width: "100%", height: "100vh", zIndex: 0, ...styleMap }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <ZoomControl position="topright" class="custom-zoom-control" />

      {latlng.latitud !== null && latlng.longitud !== null ? (
        <Marker
          icon={ICON}
          draggable={true}
          eventHandlers={eventHandlers}
          position={[latlng.latitud, latlng.longitud]}
        >
          <Tooltip direction="right" opacity={1} className="custom-tooltip">
            {tooltip}
          </Tooltip>
        </Marker>
      ) : (
        <></>
      )}

      <SearchField
        handleCoordsEvent={({ latitud, longitud }) => {
          setLatLng({ latitud, longitud });
          handleCoordsLocal({ latitud, longitud });
        }}
      />

      <GeoJSON style={style} data={polygons_mun} key={"geojson"} />
    </MapContainer>
  );
};

DragMap.propTypes = {
  ubicacion: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  center: PropTypes.array.isRequired,
};

export default DragMap;
